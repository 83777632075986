import React, { useState, useRef, useEffect } from "react"
import { navigate } from "gatsby";

import { Container, Box, TextField, Radio, RadioGroup, FormControlLabel, FormLabel, FormControl, CircularProgress, Checkbox, InputAdornment, Typography } from '@mui/material';
import { ErrorOutlineRounded, CheckCircleOutlineRounded } from '@mui/icons-material';

import Layout from "../components/layout"
import Seo from "../components/seo"
import ShowErrorMessage from "../components/showErrorMessage"
import FormHeader from "../components/formHeader"
import SendButton from "../components/sendButton"
import Error from "../components/error"
import ErrorDialog from "../components/errorDialog"

import { useQueryParam, NumberParam, StringParam } from "use-query-params";

export default function CommonContents() {
    return (
        <Layout>
            <Seo title="会員申込み" robotsType="noindex" />
            <ShowContents />
        </Layout>
    );
}

function ShowContents() {

    const [checkUuidError, setCheckUuidError] = useState(null);
    const [uuidHavechecked, setUuidHaveChecked] = useState(false);
    const [uuidQuery] = useQueryParam("uuid", StringParam);
    const [timeQuery] = useQueryParam("timestamp", NumberParam);
    const [apiState, setApiState] = React.useState({
        existance: false,
        feeExemptionApplied: false,
        registered: false
    });
    const myInit = {
        method: 'POST',
        mode: "cors",
        redirect: "follow",
        body: JSON.stringify({
            uuid: uuidQuery,
        })
    };
    console.log(myInit);
    useEffect(() => {
        fetch(`https://script.google.com/macros/s/${process.env.GATSBY_CHECK_UUID_API_DEPLOY_ID}/exec`, myInit)
            // .then((res) => { console.log(res); })
            .then(res => res.json())
            .then(
                (result) => {
                    setApiState(({ ...apiState, existance: result.existance, feeExemptionApplied: result.feeExemptionApplied, registered: result.registered }));
                    console.log(result);
                    setUuidHaveChecked(true);
                },
                (error) => {
                    setUuidHaveChecked(true);
                    setCheckUuidError(error);
                    console.log("error");
                }
            )
    }, [])
    const lapse = Date.now() - timeQuery;

    if (uuidHavechecked === false) {
        return (<CircularProgress />);
    } else if (lapse > 86400000) { // 86400000ms=24h
        return (<Error errorStatus="タイムアウトエラー" errorMessage="URLはタイムアウトしています。初めからやり直してください。" />);
    } else if (checkUuidError !== null) {
        console.log(checkUuidError);
        return (<Error errorStatus="読み込みエラー" errorMessage="ページの読み込みに失敗しました。リロードしてください。それでも解決しない場合には、初めからやり直してください。" />);
    } else if (uuidQuery === undefined || apiState.uuidExistance === false) {
        return (<Error errorStatus="URL エラー" errorMessage="URL が間違っています。認証に使用したメールアドレスにお送りした URL をご確認ください。それでも解決しない場合には、初めからやり直してください。" />);
    } else if (apiState.feeExemptionApplied === true || apiState.registered === true) {
        return (<Error errorStatus="回答済み" errorMessage="ご使用のメールアドレスでは既に回答済みです。審査終了まで今しばらくお待ちください。" />);
    } else {
        return (<Registaration uuidQuery={uuidQuery} />);
    }
}

function Registaration(props) {

    const [sendingError, setSendingError] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const textFieldUserNameRef = useRef(null);
    const textFieldRomanjiRef = useRef(null);
    const textFieldPostalCodeRef = useRef(null);
    const textFieldAddressRef = useRef(null);

    const [inputError, setInputError] = useState({
        userName: true,
        romanji: true,
        postalCode: true,
        address: true,
        restricted13: true,
        parentalConsent: true,
        feeExemption: true,
        ruleConsent: true
    });

    const [haveEdited, setHaveEdited] = useState({
        userName: false,
        romanji: false,
        postalCode: false,
        address: false,
        restricted13: false,
        parentalConsent: false,
        feeExemption: false,
        ruleConsent: false
    });

    const [inputData, setInputData] = useState({
        uuid: props.uuidQuery,
        userName: "",
        romanji: "",
        postalCode: "",
        address: "",
        restricted13: "",
        parentalConsent: "",
        feeExemption: "",
        ruleConsent: "",
        note: "",
    });

    const entrykey = {
        uuid: "entry.256326603",
        userName: "entry.237333177",
        romanji: "entry.1338918322",
        postalCode: "entry.857157872",
        address: "entry.1512757936",
        restricted13: "entry.1801147594",
        parentalConsent: "entry.1068193020",
        feeExemption: "entry.1339287823",
        ruleConsent: "entry.2109903335",
        note: "entry.2057437164",
    }

    const closeSendingError = () => {
        setSendingError(false);
    }

    const handleChackChange = (name, event, pattern) => {
        setInputData({ ...inputData, [name]: event.target.value });
        setHaveEdited(({ ...haveEdited, [name]: true }));
        var constraints = {
            [name]: [pattern]
        }
        var constraint = new RegExp(constraints[name][0]);
        if (constraint.test(event.target.value)) {
            setInputError({ ...inputError, [name]: false });
        } else {
            setInputError({ ...inputError, [name]: true });
        }
    };

    const changeHaveEditedToTrue = () => {
        setHaveEdited(({ ...haveEdited, userName: true, postalCode: true, address: true, restricted13: true, parentalConsent: true, feeExemption: true, ruleConsent: true }));
    }

    const ValidationIcon = (props) => {
        if (!inputError?.[props.inputErrorName] && haveEdited?.[props.inputErrorName]) {
            return (<InputAdornment position="end"><CheckCircleOutlineRounded color="success" /></InputAdornment>);
        } else if (haveEdited?.[props.inputErrorName]) {
            return (<InputAdornment position="end"><ErrorOutlineRounded color="error" /></InputAdornment>);
        } else {
            return (<></>);
        }
    }

    const checkSubmission = async (event) => {
        setButtonDisabled(true);
        changeHaveEditedToTrue();
        const submitter = event.nativeEvent.submitter;
        event.preventDefault();
        if (submitter === null) {
            setButtonDisabled(false);
            return false;
        } else if (submitter.id !== "sendButton") {
            setButtonDisabled(false);
            return false;
        } else {
            let formData = "";
            const entry = Object.keys(entrykey);
            for (let index = 0; index < entry.length; index++) {
                formData += `${entrykey?.[entry[index]]}=${inputData?.[entry[index]]}`;
                if (index < entry.length - 1) {
                    formData += "&";
                }
            }
            const myInit = {
                method: 'POST',
                mode: "cors",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': "text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8",
                },
                redirect: 'follow',
                body: encodeURI(formData)
            };
            await fetch(`https://secure-headland-84331.herokuapp.com/https://docs.google.com/forms/d/e/1FAIpQLSdsIwsT2_I58CuJO5EUwTaKdekykn6MRbwsPU9voRW43RRnRQ/formResponse`, myInit)
                .then(response => {
                    const result = response;
                    console.log(result.status);
                    if (!result.ok) {
                        console.error('サーバーエラー');
                    } else {
                        navigate("/submitted", { state: { title: "個人情報の登録等", message: "個人情報の登録等が完了しました。確認のメールをお送りいたしましたので、ご確認ください。もしメールが半日以内に届かない場合には、contact@techfeelo.orgまでご連絡ください。また、学生向けの費用免除をご希望の方は続けてメールのURLから申請を行ってください。" } })
                    }
                })
                .catch(() => {
                    console.log('エラー');
                    setSendingError(true);
                    setButtonDisabled(false);
                })
        }
    }

    return (
        <>
            <FormHeader currentStep={2} />
            <Container component="main" maxWidth="sm" style={{ marginTop: "3%", marginBottom: "5%" }}>
                <Box
                    component="form"
                    onSubmit={(event) => { checkSubmission(event); }}

                >
                    <Typography variant="h3">会員情報登録</Typography>
                    <TextField
                        margin="normal" variant="outlined" fullWidth autoFocus={true}
                        label="氏名" id="userName" required
                        autoComplete="name"
                        helperText="氏名の区切りには半角スペースを用いてご記入下さい。ひらがな、カタカナ、漢字、英数字、ロシア文字、ギリシャ文字、ローマ数字、主要なアクセント文字にも対応しています。"
                        onChange={e => {
                            handleChackChange("userName", e, "[A-zΑ-ωА-я0-9Ⅰ-Ⅹⅰ-ⅹぁ-ヴー亜-熙àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ ]+");
                        }}
                        inputRef={textFieldUserNameRef}
                        inputProps={{
                            onKeyPress: event => {
                                const { key } = event;
                                if (key === "Enter") {
                                    textFieldRomanjiRef.current?.focus();
                                }
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <ValidationIcon inputErrorName="userName" />
                            )
                        }}
                        error={inputError.userName && haveEdited.userName}
                    />
                    <TextField
                        margin="normal" variant="outlined" fullWidth
                        label="ローマ字" id="romanji" required
                        autoComplete="name"
                        helperText="氏名の区切りには半角スペースを用いてご記入ください。使用可能な文字はアルファベット、アラビア数字、ローマ数字のみです。"
                        onChange={e => {
                            handleChackChange("romanji", e, "[A-z0-9Ⅰ-Ⅹⅰ-ⅹ ]+");
                        }}
                        inputRef={textFieldRomanjiRef}
                        inputProps={{
                            onKeyPress: event => {
                                const { key } = event;
                                if (key === "Enter") {
                                    textFieldPostalCodeRef.current?.focus();
                                }
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <ValidationIcon inputErrorName="romanji" />
                            )
                        }}
                        error={inputError.romanji && haveEdited.romanji}
                    />
                    <TextField
                        margin="normal" variant="outlined"
                        label="郵便番号" id="postalCode" required
                        autoComplete="postal-code"
                        helperText="ハイフンなしの半角7桁でご記入ください。"
                        onChange={e => {
                            handleChackChange("postalCode", e, "^[0-9]{7}$");
                        }}
                        inputRef={textFieldPostalCodeRef}
                        inputProps={{
                            onKeyPress: event => {
                                const { key } = event;
                                if (key === "Enter") {
                                    textFieldAddressRef.current?.focus();
                                }
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <ValidationIcon inputErrorName="postalCode" />
                            )
                        }}
                        error={inputError.postalCode && haveEdited.postalCode}
                    />
                    <TextField
                        margin="normal" variant="outlined" fullWidth
                        label="住所" id="address" required
                        helperText="都道府県から、マンション・アパート名・部屋番号までご記入下さい。"
                        onChange={e => {
                            handleChackChange("address", e, "^.+[都道府県].+[市区町村].+$");
                        }}
                        inputRef={textFieldAddressRef}
                        InputProps={{
                            endAdornment: (
                                <ValidationIcon inputErrorName="address" />
                            )
                        }}
                        error={inputError.address && haveEdited.address}
                    />

                    <Typography variant="h3">規約等の確認</Typography>

                    <FormControl sx={{ p: 1, borderRadius: 1, mb: "3%" }} error={inputError.restricted13 && haveEdited.restricted13}>
                        <FormLabel>会員になろうとする者は、13歳以上ですか？ *</FormLabel>
                        <Typography variant="body2">
                            techfeeloでは、正会員（個人）間の交流にDiscordを用いています。Discordのサービス利用規約の関係上、13歳未満の方が入会を希望される場合には個別に対応させていただきますので、 contact@techfeelo.org までご連絡ください。
                        </Typography>
                        <FormControlLabel
                            style={{ padding: "0rem 1rem" }}
                            control={<Checkbox color="primary" value="はい" required />}
                            label="はい"
                            onChange={e => {
                                setInputData({ ...inputData, restricted13: e.target.value });
                                if (haveEdited.restricted13 && !inputError.restricted13) {
                                    setInputError({ ...inputError, "restricted13": true });
                                } else {
                                    setInputError({ ...inputError, "restricted13": false });
                                }
                                setHaveEdited({ ...haveEdited, "restricted13": true });
                            }}
                        />
                        <ShowErrorMessage error={haveEdited.restricted13 && inputError.restricted13} errorMessage="選択してください。" />
                    </FormControl>

                    <FormControl sx={{ p: 1, borderRadius: 1, mb: "3%" }} error={inputError.parentalConsent && haveEdited.parentalConsent}>
                        <FormLabel>会員になろうとする者が未成年者の場合には、保護者の同意を得ていますか？ *</FormLabel>
                        <RadioGroup
                            aria-label="未成年者の保護者の同意"
                            style={{ padding: "0rem 1rem" }}
                            onChange={e => {
                                setInputData({ ...inputData, parentalConsent: e.target.value });
                                setInputError({ ...inputError, "parentalConsent": false });
                            }}
                        >
                            <FormControlLabel control={<Radio required />} label="はい" value="はい" />
                            <FormControlLabel control={<Radio required />} label="成人済み" value="成人済み" />
                        </RadioGroup>
                        <ShowErrorMessage error={haveEdited.parentalConsent && inputError.parentalConsent} errorMessage="選択してください。" />
                    </FormControl>

                    <FormControl sx={{ p: 1, borderRadius: 1, mb: "3%" }} error={inputError.feeExemption && haveEdited.feeExemption}>
                        <FormLabel>学生・生徒向けの会費の免除を希望しますか？ *</FormLabel>
                        <Typography variant="body2">
                            一般社団法人techfeeloでは、入会の申込み日時点で、30歳以下の学生・生徒及びこれらに準じると当法人事務局が判断した正会員（個人）に対し、入会金、年会費ともに半額を免除しています。
                            なお、希望される場合には、このフォームの入力完了後にお送りするメールに記載されたリンクより、在籍校及びその種別・生年月日・在籍証明書類のアップロードをお願いしております。
                            （一般社団法人techfeelo 会員規約第6条第4項の2以外の事由での免除を申請する場合には、このフォームでの申込みを行う前に contact@techfeelo.org までお問い合わせください。）
                        </Typography>
                        <RadioGroup
                            aria-label="会費免除"
                            style={{ padding: "0rem 1rem" }}
                            onChange={e => {
                                setInputData({ ...inputData, feeExemption: e.target.value });
                                setInputError({ ...inputError, feeExemption: false });
                            }}
                        >
                            <FormControlLabel control={<Radio required />} label="希望する" value="希望する" />
                            <FormControlLabel control={<Radio required />} label="希望しない" value="希望しない" />
                        </RadioGroup>
                        <ShowErrorMessage error={haveEdited.feeExemption && inputError.feeExemption} errorMessage="選択してください。" />
                    </FormControl>

                    <FormControl sx={{ p: 1, borderRadius: 1, mb: "3%" }} error={inputError.ruleConsent && haveEdited.ruleConsent}>
                        <FormLabel>会員規約・個人情報保護方針を確認・同意の上、入会を希望しますか？ *</FormLabel>
                        <FormControlLabel
                            style={{ padding: "0rem 1rem" }}
                            control={<Checkbox color="primary" value="はい" required />}
                            label="はい"
                            onChange={e => {
                                setInputData({ ...inputData, ruleConsent: e.target.value });
                                if (haveEdited.ruleConsent && !inputError.ruleConsent) {
                                    setInputError({ ...inputError, "ruleConsent": true });
                                } else {
                                    setInputError({ ...inputError, "ruleConsent": false });
                                }
                                setHaveEdited({ ...haveEdited, "ruleConsent": true });
                            }}
                        />
                        <ShowErrorMessage error={haveEdited.ruleConsent && inputError.ruleConsent} errorMessage="選択してください。" />
                    </FormControl>

                    <TextField
                        margin="normal" label="備考記入欄" fullWidth multiline rows={2} variant="outlined"
                        helperText="正会員（個人）に申し込むにあたり、一般社団法人techfeeloにお伝えになりたいことがある方は、こちらにご記入ください。"
                        onChange={e => { setInputData({ ...inputData, note: e.target.value }); }}
                    />
                    <SendButton changeHaveEditedToTrue={changeHaveEditedToTrue} inputError={inputError} haveEdited={haveEdited} disabled={buttonDisabled} />
                </Box>
                <ErrorDialog open={sendingError} closeSendingError={closeSendingError} />
            </Container>
        </>
    );
}